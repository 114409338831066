/* istanbul ignore file */
import { ENABLE_MSW } from '@/common/constants'
import * as BridgeService from '@/services/bridge'

import { FetchOptions } from './types'

export async function api<T = Record<string, unknown>>(
  url: string,
  options: FetchOptions['options'],
): Promise<T | undefined> {
  const endpoint = `/${url}`
  try {
    const response = await BridgeService.request<T>({
      endpoint,
      body: options.body,
      headers: {
        ...(options.headers || {}),
        // TODO: remove this after api don't require more
        'x-inter-so': BridgeService.environment,
        'x-versao-so': '11',
        'Content-Type': 'application/json',
      },
      method: options.method,
    })

    if (response && (response as FetchError).errors) {
      throw new Error(
        JSON.stringify({
          error: (response as FetchError).errors,
          endpoint,
        }),
      )
    }

    return response as T
  } catch (error) {
    if (ENABLE_MSW) {
      const data = await fetch(`${window.location.origin}/${url}`, {
        method: options.method,
        headers: {
          ...(options.headers || {}),
          'Content-Type': 'Application/JSON',
        },
      })

      try {
        const json = await data.json()
        return Promise.resolve(json)
      } catch (_mswError) {
        return undefined
      }
    }

    return Promise.reject(error)
  }
}
