import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--neutral-theme);
  height: 100%;
  display: flex;
  justify-content: start;
  flex-flow: column wrap;
  align-items: center;
  margin: 30px 24px;

  button {
    margin-top: auto;
    margin-bottom: 24px;
  }
`

export const OrderedListContainer = styled.div`
  padding: 0 5%;
  margin-bottom: 50px;

  & > div > div {
    margin-left: 0;
    margin-right: auto;
  }
`
