import { useCallback, useEffect, useState } from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { useInterval } from '@interco/lib-util'
import { ApiService } from '@/services'
import * as BridgeService from '@/services/bridge'
import { QrCodeStatus, ChallengeErrors } from '@/common/enums'

import { Matrix } from './Matrix'
import { Feedback } from './Feedback'
import * as S from './styles'
import * as Types from './types'

const CHECK_STATUS_INTERVAL = 3_000
const CHECK_STATUS_TIMEOUT = 15 * CHECK_STATUS_INTERVAL

export const Challenge = ({
  selectedValues,
  closeWebview,
  browserAndOs,
  qrCode,
}: Types.ChallengeProps) => {
  const [currentFeedback, setCurrentFeedback] = useState<Types.Feedbacks>(Types.Feedbacks.EMPTY)
  const [stopPolling, setStopPolling] = useState<boolean>(false)
  const [calledAction, setCalledAction] = useState({
    [BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_SUCCESS]: false,
    [BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_ERROR]: false,
  })

  useEffect(() => {
    setTimeout(() => {
      const shouldCallTimeoutFeedback =
        calledAction[BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_ERROR] === false

      if (shouldCallTimeoutFeedback) {
        BridgeService.navigateToChallengeError(ChallengeErrors.CHALLENGE_TIME_EXPIRED)

        setCalledAction((prevState) => ({
          ...prevState,
          [BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_ERROR]: true,
        }))
      }
    }, 60_000)
  }, [calledAction])

  const checkStatus = useCallback(() => {
    ApiService.CHALLENGE.checkStatus(qrCode)
      .then((response) => {
        const shouldCallSuccessFeedback =
          response?.status === QrCodeStatus.LOGGED &&
          calledAction[BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_SUCCESS] === false

        if (shouldCallSuccessFeedback) {
          BridgeService.navigateToChallengeSuccess()

          setCalledAction((prevState) => ({
            ...prevState,
            [BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_SUCCESS]: true,
          }))
        }
      })
      .catch((checkStatusError) => {
        const mappedErros = { ...ChallengeErrors } as unknown as Record<ChallengeErrors, unknown>
        const error: { statusLogin: ChallengeErrors } = checkStatusError
        const shouldCallErrorFeedback = !!mappedErros[error.statusLogin]

        if (shouldCallErrorFeedback) {
          BridgeService.navigateToChallengeError(error.statusLogin)

          setCalledAction((prevState) => ({
            ...prevState,
            [BridgeService.Types.Actions.NAVIGATE_TO_CHALLENGE_ERROR]: true,
          }))
        }
      })
  }, [qrCode, calledAction])

  useInterval(checkStatus, stopPolling ? null : CHECK_STATUS_INTERVAL)

  useEffect(() => {
    setTimeout(() => setStopPolling(true), CHECK_STATUS_TIMEOUT)
  }, [])

  return (
    <>
      <S.TitleContainer>
        <Text variant="headline-h2" as="h1" colorWeight={500}>
          Autorização de Navegador
        </Text>
      </S.TitleContainer>

      <S.TitleBrowser>
        <Text variant="caption-1" as="p" colorWeight={400}>
          {browserAndOs}
        </Text>
      </S.TitleBrowser>

      <S.TitleFeedback>
        <Text variant="body-3" as="p" colorWeight={400}>
          Selecione no seu navegador os itens que aparecem destacados como na imagem abaixo
        </Text>
      </S.TitleFeedback>

      <Matrix selectedValues={selectedValues} />

      <Button variant="secondary" fullWidth onClick={closeWebview}>
        Fechar
      </Button>

      <Feedback
        current={currentFeedback}
        onClose={() => setCurrentFeedback(Types.Feedbacks.EMPTY)}
      />
    </>
  )
}
