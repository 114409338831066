import React, { SVGAttributes } from 'react'

export default function MatrixOn(props?: SVGAttributes<SVGSVGElement>) {
  return (
    <svg
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="40.5" cy="40" r="29" stroke="var(--highlight-color)" strokeWidth="2" />
      <circle cx="41" cy="39.5" r="17.5" fill="var(--highlight-color)" />
    </svg>
  )
}
