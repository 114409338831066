import * as S from './styles'
import * as Types from './types'

const MATRIX_VALUES = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
]

export const Matrix = ({ selectedValues }: Types.MatrixProps) => (
  <S.MatrixContainer>
    {MATRIX_VALUES.map((row) => (
      <S.MatrixRow>
        {row.map((value) =>
          selectedValues.includes(value) ? <S.MatrixItemChecked /> : <S.MatrixItemUnchecked />,
        )}
      </S.MatrixRow>
    ))}
  </S.MatrixContainer>
)
