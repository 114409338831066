import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import * as Types from './types'
import * as S from './styles'

export const Feedback = ({ onClose, current }: Types.FeedbackProps) => {
  switch (current) {
    case Types.Feedbacks.CHALLENGE_UNAVAILABLE:
      return (
        <BottomSheet onClose={onClose}>
          <S.BottomSheetContainer>
            <Text variant="headline-h3" semiBold>
              Desafio indisponível
            </Text>

            <Text variant="body-3">Por favor, tente novamente mais tarde.</Text>

            <Button onClick={onClose} fullWidth>
              Entendi
            </Button>
          </S.BottomSheetContainer>
        </BottomSheet>
      )

    case Types.Feedbacks.CHALLENGE_ERROR:
      return (
        <BottomSheet onClose={onClose}>
          <S.BottomSheetContainer>
            <Text variant="headline-h3" semiBold>
              Erro no desafio
            </Text>

            <Text variant="body-3">
              O desafio foi completado incorretamente. Por favor, tente novamente.
            </Text>

            <Button fullWidth>Tentar novamente</Button>
          </S.BottomSheetContainer>
        </BottomSheet>
      )

    case Types.Feedbacks.TIME_EXPIRED:
      return (
        <BottomSheet onClose={onClose}>
          <S.BottomSheetContainer>
            <Text variant="headline-h3" semiBold>
              Tempo expirado
            </Text>

            <Text variant="body-3">
              O tempo do desafio apresentado expirou. Por favor, tente novamente.
            </Text>

            <Button fullWidth>Tentar novamente</Button>
          </S.BottomSheetContainer>
        </BottomSheet>
      )

    case Types.Feedbacks.EMPTY:
    default:
      return <></>
  }
}
