import InterWebviewBridge, {
  interWbHttp,
  interWbNavigate,
  IWbHttpOptions,
  IWbHttpData,
  WbEnvironments,
} from '@interco/inter-webview-bridge'
import { ChallengeErrors } from '@/common/enums'

import * as Types from './types'

export { interWbNavigate, Types }
export const iWb = InterWebviewBridge.getInstance()
export const environment = iWb.getEnvironment()
export const inApp = environment !== WbEnvironments.BROWSER

export async function request<T = Record<string, unknown>>({
  method,
  endpoint,
  ...args
}: Types.Request): Promise<T | string> {
  if (!inApp) return Promise.reject(new Error(''))

  let response: IWbHttpData
  const headers = args.headers || {}
  const options: IWbHttpOptions = { timeout: 30_000, ...args.options }

  switch (method) {
    default:
    case 'GET':
    case 'DELETE':
      response = await interWbHttp[method.toLowerCase() as 'get' | 'delete'](
        endpoint,
        headers,
        options,
      )
      break

    case 'POST':
    case 'PUT':
      response = await interWbHttp[method.toLowerCase() as 'post' | 'put'](
        endpoint,
        (args as unknown as Types.PostOrPut).body,
        headers,
        options,
      )
      break
  }

  const data = response

  if (data.httpStatus === 200 || data.httpStatus === 201) {
    try {
      return JSON.parse(data.response)
    } catch (error) {
      return data.response
    }
  }

  return data.response
}

export const requestGoBack = async (): Promise<unknown> => {
  if (inApp) {
    try {
      await interWbNavigate.requestGoBack()
      return Promise.resolve()
    } catch (error) {
      window.console.log(error)
    }
  }

  return Promise.resolve(undefined)
}

export const navigateToChallengeSuccess = async (): Promise<void> => {
  if (inApp) {
    try {
      await iWb.execute({ action: Types.Actions.NAVIGATE_TO_CHALLENGE_SUCCESS })
    } catch (error) {
      window.console.log(error)
    }
  }

  return Promise.resolve(undefined)
}

export const navigateToChallengeError = async (type: ChallengeErrors): Promise<void> => {
  if (inApp) {
    try {
      const metadata: Record<ChallengeErrors, { title: string; body: string }> = {
        [ChallengeErrors.CHALLENGE_ERROR]: {
          title: 'Erro no desafio',
          body: 'O desafio foi completado incorretamente. Por favor, tente novamente.',
        },
        [ChallengeErrors.CHALLENGE_UNAVAILABLE]: {
          title: 'Desafio indisponível',
          body: 'Por favor, tente novamente mais tarde.',
        },
        [ChallengeErrors.CHALLENGE_FAILURE]: {
          title: 'Desafio indisponível',
          body: 'Por favor, tente novamente mais tarde.',
        },
        [ChallengeErrors.CHALLENGE_TIME_EXPIRED]: {
          title: 'Tempo expirado',
          body: 'O tempo do desafio apresentado expirou. Por favor, tente novamente.',
        },
      }

      await iWb.execute({
        action: Types.Actions.NAVIGATE_TO_CHALLENGE_ERROR,
        metadata: metadata[type],
      })
    } catch (error) {
      window.console.log(error)
    }
  }

  return Promise.resolve(undefined)
}
