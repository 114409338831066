import styled from 'styled-components'

import MatrixOn from '@/assets/MatrixOn'
import MatrixOff from '@/assets/MatrixOff'

export const TitleContainer = styled.div`
  h1 {
    text-align: center;
    font-weight: bold !important;
  }
`

export const TitleBrowser = styled.div`
  p {
    text-align: center;
    margin-top: 16px;
  }
`

export const TitleFeedback = styled.div`
  p {
    text-align: center;
    margin-top: 16px;
    margin-bottom: 42px;
  }
`

const MatrixContainerSize = 272
export const MatrixContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  min-height: ${MatrixContainerSize}px;
  max-height: ${MatrixContainerSize}px;
  min-width: ${MatrixContainerSize}px;
  max-width: ${MatrixContainerSize}px;
`

export const MatrixRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`

export const MatrixItemChecked = styled(MatrixOn)`
  circle {
    cursor: pointer;
  }
`

export const MatrixItemUnchecked = styled(MatrixOff)``

export const BottomSheetContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  span + span {
    margin-top: 12px;
    margin-bottom: 32px;
  }

  button {
    margin-bottom: 0;
  }
`
