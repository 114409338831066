import { IWbHttpOptions, WbSessionActions } from 'inter-webview-bridge'

enum CustomActions {
  NAVIGATE_TO_CHALLENGE_SUCCESS = 'navigateToChallengeSuccess',
  NAVIGATE_TO_CHALLENGE_ERROR = 'navigateToChallengeError',
}

export const Actions = {
  ...WbSessionActions,
  ...CustomActions,
}

type CommonArguments = {
  endpoint: string
  headers?: Record<string, string>
  options?: IWbHttpOptions
}

type GetOrDelete = CommonArguments & {
  method: 'GET' | 'DELETE'
}

export type PostOrPut = CommonArguments & {
  method: 'POST' | 'PUT'
  body?: Record<string, unknown>
}

export type Request = GetOrDelete | PostOrPut
